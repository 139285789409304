const query = `mutation SEND_INQUIRY_IWP($input: SendInquiryIwpInput!) {
  sendInquiryIwp(input: $input) {
    status
    message
    sugar
  }
}
`;

const getContactFormMutationIwp = (adviserId = "aa") => {
  return async formData => {
    const graphqlUrl = process.env.WP_GRAPHQL_URI ? process.env.WP_GRAPHQL_URI : "/graphql";
    const result = await fetch(graphqlUrl, {
      // eslint-disable-line no-undef
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: {
          input: {
            clientMutationId: "inq" + new Date().getTime(),
            adviserId: adviserId.toString(),
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: formData.phone,
            email: formData.email,
            gdpr: formData.gdpr,
            message: formData.message,
            resumeName: formData.adviserId,
          },
        },
      }),
    });
    return await result.json();
  };
};

export { getContactFormMutationIwp };
