import React from "react";
import { getContactFormMutationIwp } from "../../../components/ContactForm/ContactFormMutationIwp";
import { Container, Flex, Wrapper } from "../../../style/Grid";
import { Heading } from "../../../style/Heading";
import { Image } from "../../../style/Image";
import ContactBlockBranch from "../../../components/ContactBlock/ContactBlockBranch";
import ContactForm from "../../../components/ContactForm";

const IwpContactBlock = ({ contactInfoWithName, adviserPhoto, adviserId }) => {
  return (
    <Wrapper bg={"panelPrimary"} id={"contact-form"} border={0} borderTop={"4px"} borderColor={"cta"} borderStyle={"solid"}>
      <Container justifyContent={"center"}>
        <Flex flexDirection={["column", null, "row"]} justifyContent={"space-between"} width={1} maxWidth={894} mt={33} mb={[92, null, 57]}>
          <Flex flexDirection={"column"} alignItems={"flex-start"} justifyContent={"flex-start"} mr={[0, null, 4]} mb={[4, null, 0]}>
            <Heading as={"h3"} display={["block", null, "none"]} fontSize={28} lineHeight={"29px"} fontWeight={300} mb={3} uppercase>
              Kontaktovat poradce
            </Heading>
            <Image width={1} maxWidth={[165, null, 285]} mb={4} height={"auto"} boxShadow="one" borderRadius={1} src={adviserPhoto} />
            <ContactBlockBranch contact={contactInfoWithName} />
          </Flex>
          <Flex flexDirection={"column"} width={1} maxWidth={["auto", null, null, 385]}>
            <Heading as={"h3"} display={["none", null, "block"]} fontSize={28} lineHeight={"29px"} fontWeight={300} mb={3} uppercase>
              Kontaktovat poradce
            </Heading>
            <ContactForm type="adviser" title="Kontaktovat poradce" adviserId={adviserId} customMutation={getContactFormMutationIwp(adviserId)} />
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default IwpContactBlock;
