import React from "react";
import { Box, Flex } from "../../style/Grid";
import Link from "../Link";
import Icon from "../../style/Icon";
import { Link as FakeLink } from "../../style/Link";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";

const products = [
  {
    image: require("../../assets/images/iwp/keltove.jpg").default,
    url: "https://www.kapitolgold.cz/mince/oppidum-stradonice/",
    name: "Zlatá mince Oppidum\xa0Stradonice",
    price: "64 900",
  },
  {
    image: require("../../assets/images/iwp/bata.jpg").default,
    url: "https://www.kapitolgold.cz/mince/tomas-bata/",
    name: "Stříbrná mince Tomáš\xa0Baťa",
    price: "2 099",
  },
];

const GoldProducts = ({ adviser }) => {
  return (
    <Flex mb={4} flexWrap={"wrap"} flexDirection={["column", "row"]}>
      {products.map(product => (
        <Flex flexDirection={["column", null, "row"]} maxWidth={["100%", "50%"]} alignItems={"center"} mb={[4, 0]}>
          <Box
            minWidth={["43%"]}
            bg={"white.100"}
            borderRadius={"6px"}
            borderWidth={[0, null, "1px"]}
            borderColor={"panelSecondary"}
            borderStyle={"solid"}
            overflow={"hidden"}
            position={"relative"}
          >
            <a href={`${product.url}?${adviser}`}>
              <Box
                as={"img"}
                src={product.image}
                alt={product.name}
                maxWidth={["320px", "auto"]}
                style={{ width: "100%", objectFit: "cover", top: 0, left: 0, right: 0, bottom: 0 }}
              />
            </a>
          </Box>

          <Box minWidth={"57%"} pl={[0, null, 4]}>
            <Heading as="h3" fontSize={[18, null, 3]} lineHeight={["21px", null, "23px"]} fontWeight={400} mb={26}>
              <Text as={"a"} href={product.url} color={"gold.headlines"}>
                {product.name}
              </Text>
            </Heading>
            <Heading as="h3" fontSize={[18, null, 4]} lineHeight={["21px", null, "23px"]} fontWeight={400} color={"gold.headlines"} mb={26}>
              {product.price}{" "}
              <Text as="span" fontSize={16} verticalAlign={"text-top"} color={"gold.headlines"}>
                Kč
              </Text>
            </Heading>

            <FakeLink variant="smallBorderTop" mt={30} as={Link} href={product.url + `?${adviser}`}>
              Detail Produktu
              <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="gold.headlines" />
            </FakeLink>
          </Box>
        </Flex>
      ))}
    </Flex>
  );
};

export default GoldProducts;
