import React, { useState } from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import { Box, Container, Flex, Wrapper } from "../style/Grid";
import Breadcrumb from "../components/Breadcrumb";
import { Heading } from "../style/Heading";
import { URLS } from "../const/const";
import { graphql } from "gatsby";
import { Text } from "../style/Text";
import { Image } from "../style/Image";
import { Link as FakeLink } from "../style/Link";
import Icon from "../style/Icon";
import TagLink from "../components/TagLink";
import { Button } from "../style/Button";
import MagazineBlockBig from "../components/MagazineBlock/MagazineBlockBig";
import CareerBlock from "../components/CareerBlock";
import ContactBlockBranch from "../components/ContactBlock/ContactBlockBranch";
import { htmlDecode } from "../utils";
import Blockquote from "../style/Blockquote";
import HowCanWeHelp from "../components/AdvantageBlock/HowCanWeHelp";
import { StaticImage } from "gatsby-plugin-image";
import Link from "../components/Link";
import IwpGold from "./parts/iwp/iwpGold";
import IwpContactBlock from "./parts/iwp/IwpContactBlock";

const divisions = [
  {
    name: "Komplexní finanční poradenství",
    description: "Poradenství v oblastech, jako je životní a majetkové pojištění nebo úvěry a investice, jsou hlavním stavebním pilířem této divize.",
    link: "/",
  },
  {
    name: "Reality",
    description:
      "Makléřům umíme zajistit práci. Díky statisícům klientů v naší databázi jsme schopni získat nemovitosti do nabídky a zajistit právní podporu. ",
    link: "https://www.kapitolreality.cz",
  },
  {
    name: "Cenné kovy",
    description:
      "Investiční slitek jako speciální dárek k narozeninám nebo výročí? O spoření do zlata a stříbra je u klientů stále vyšší zájem, tak toho využijte.",
    link: "https://kapitolgold.cz",
  },
];

const IwpTemplate = props => {
  require("moment/locale/cs");
  const {
    uri,
    data: {
      wp: {
        posts: { nodes: posts },
      },
      wpGold: {
        posts: { nodes: goldPosts },
      },
    },
    pageContext: { title, adviser },
  } = props;

  const [productLimit, setProductLimit] = useState(3);
  const products = adviser.specializedInProducts;

  const socialKeys = ["facebook", "instagram", "linkedin"];
  const socialLabels = ["Facebook", "Instagram", "LinkedIn"];

  const breadcrumbs = [
    {
      label: "Kontakt",
      link: URLS.contact,
    },
    {
      label: title,
      link: uri,
    },
  ];

  const bannerCareer = {
    fieldGroupName: "banner",
    title: "MÁTE ZÁJEM SPOLUPRACOVAT S&nbsp;KAPITOLEM?",
    cta: {
      target: "",
      title: "Kariéra",
      url: "/kariera",
    },
    content:
      "<p>Plánujete svoji budoucnost v pozici samostatného konzultanta nebo jako manažer při budování svého týmu? Jste zkušený profesionál a uvažujete o vedení vlastního obchodního zastoupení KAPITOLu? Vždy vám vyjdeme maximálně vstříc a pomůžeme vám udělat dobré rozhodnutí k dosažení úspěšné kariéry.</p>",
  };

  const contactInfo = {
    mail: adviser.contact.mail,
    phone: adviser.contact.phone,
    address: adviser.contact.address,
  };

  const contactInfoWithName = {
    name: title,
    ...contactInfo,
  };

  const adviserPhoto = adviser.photo ? adviser.photo : require("../assets/images/placeholder/test-image-13.jpg").default;

  return (
    <Layout>
      <Header variant="light" />

      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column" mt={4} mb={3}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Heading
              maxWidth={"780px"}
              as="h1"
              fontSize={[5, null, 7]}
              lineHeight={["35px", null, "48px"]}
              fontWeight={["light", null, "extraLight"]}
              mt={3}
              pb={4}
              uppercase
            >
              {htmlDecode(title)}
            </Heading>
            {adviser.position && (
              <Heading as="h2" fontSize={[4, null, 5]} lineHeight={["28px", null, "38px"]} fontWeight={300} mt={2}>
                {adviser.position}
              </Heading>
            )}
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="panelPrimary" pt={[0, null, 4]} pb={[0, null, 60]}>
        <Container>
          <Flex width={1} mb={4}>
            <Flex maxWidth="800px" flexGrow={1} mr={[0, null, "20px"]} flexDirection={["column", null, null, "row"]}>
              <Flex
                mt={"-20px"}
                mx={"-15px"}
                flexGrow={1}
                display={["block", null, "none"]}
                boxShadow="one"
                pb={[0, null, 5]}
                bg="white.100"
                maxWidth={"400px"}
                alignSelf={"center"}
              >
                <Image width="100%" display="block" src={adviserPhoto} style={{ objectFit: "cover" }} />
                {adviser.motto && (
                  <Box alignSelf={"end"} maxWidth={["none", null, "285px"]} width={["auto", null, 1]} mt={[27]} mb={[47, null, 30]}>
                    <Blockquote>
                      <Text>{htmlDecode(adviser.motto)}</Text>
                    </Blockquote>
                  </Box>
                )}
              </Flex>
              <Box flexGrow={1} width={1} mr={[0, null, 3]} mb={[32, null, 0]} pr={[0, null, 3]} mt={[34, null, 0]}>
                <Box maxWidth={["auto", null, 710]}>
                  <ContactBlockBranch coords={adviser.contact.coords} contact={contactInfo} mb={32} />
                  {adviser.introduction && (
                    <Flex width={1} mb={32} flexDirection={"column"}>
                      <Heading as="h4" fontSize={2} lineHeight={"19px"} fontWeight="semiBold" color="titaniumGrey" uppercase mb={12}>
                        VÍCE O MNĚ
                      </Heading>
                      <Box width={1}>
                        <Text>{adviser.introduction}</Text>
                      </Box>
                    </Flex>
                  )}
                  {socialKeys.some(key => adviser.social[key]) && (
                    <Flex width={1} mb={32} flexDirection={"column"}>
                      <Heading as="h4" fontSize={2} lineHeight={"19px"} fontWeight="semiBold" color="titaniumGrey" uppercase mb={12}>
                        BUĎME VE SPOJENÍ
                      </Heading>
                      <Flex width={1} flexDirection={["column", "row"]} flexWrap={"wrap"}>
                        {socialKeys.map((socialKey, i) => {
                          if (!adviser.social[socialKey]) {
                            return null;
                          }

                          return (
                            <Flex as={"a"} href={adviser.social[socialKey]} key={socialKey} alignItems={"center"} my={1} mr={4}>
                              <Icon icon={socialKey} color="cta" size={[18, null, 24]} mr={2} />
                              <Text uppercase color={"link"} fontWeight={400} fontSize={[15, null, 20]}>
                                {socialLabels[i]}
                              </Text>
                            </Flex>
                          );
                        })}
                      </Flex>
                    </Flex>
                  )}

                  {products && (
                    <Flex flexDirection={"column"}>
                      <Heading as="h4" fontSize={2} lineHeight={"19px"} fontWeight="semiBold" color="titaniumGrey" uppercase mb={12}>
                        Specializace
                      </Heading>
                      <Flex alignItems="center" flexWrap="wrap" mb={3}>
                        {products.slice(0, productLimit).map((product, index) => (
                          <TagLink key={index} href={`/s-cim-pomuzeme/`} content={product.description} icon={"dolar-icon"} variant={"outline"} />
                        ))}
                      </Flex>
                      {!(productLimit >= products.length) && (
                        <Box>
                          <FakeLink variant="smallBorderTop" onClick={() => setProductLimit(productLimit + 3)}>
                            Více
                            <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="cta" />
                          </FakeLink>
                        </Box>
                      )}
                    </Flex>
                  )}
                </Box>
                {adviser.clubs && adviser.clubs.length > 0 && (
                  <Flex width={1} flexDirection={["column", "row"]}>
                    {adviser.clubs?.map(club => {
                      return (
                        <Flex key={club.code} alignItems={"center"} my={1} mr={4}>
                          {club.code && (
                            <Image width={32} height={32} src={require("../assets/images/iwp/" + club.code.toLowerCase() + ".svg").default} mr={2} />
                          )}
                          {club.description && (
                            <Text uppercase color="nightBlue" fontWeight={600}>
                              {club.description?.toUpperCase()}
                            </Text>
                          )}
                        </Flex>
                      );
                    })}
                  </Flex>
                )}
              </Box>
            </Flex>

            <Flex flexGrow={1} maxWidth={"384px"} display={["none", null, "flex"]} justifyContent={"flex-end"} flexDirection={"column"}>
              <Image
                maxWidth="384px"
                mt={[0, null, "-20%", "-45%"]}
                boxShadow="one"
                width="100%"
                borderRadius={1}
                display="block"
                src={adviserPhoto}
                style={{ objectFit: "cover" }}
              />
              {adviser.motto && (
                <Box alignSelf={"end"} maxWidth={["none", null, "384px"]} width={["auto", null, 1]} mt={[27]} mb={[47, null, 30]}>
                  <Blockquote>
                    <Text>{htmlDecode(adviser.motto)}</Text>
                  </Blockquote>
                </Box>
              )}
            </Flex>
          </Flex>
        </Container>

        <Container>
          <Flex flexWrap="wrap" justifyContent="space-between">
            {divisions.map((division, index) => (
              <Flex
                px={3}
                pt={2}
                pb={3}
                key={index}
                width={[1, "calc(33.333% - 10px)", null, "calc(33.333% - 20px)"]}
                flexDirection={"column"}
                justifyContent={["space-between"]}
                borderRadius={1}
                mb={[3, null, 4, 5]}
                bg="white.100"
              >
                <Box mb={3}>
                  <Heading as="h3" fontSize={[2, null, 3]} fontWeight="semiBold" mb={2} mt={[2, 0]} color="link">
                    {division.name}
                  </Heading>

                  <Text lineHeight={2} fontSize={[15, 16]} dangerouslySetInnerHTML={{ __html: division.description }} />
                </Box>
                <FakeLink as={"a"} variant="smallBorderTop" href={division.link}>
                  Více
                  <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="cta" />
                </FakeLink>
              </Flex>
            ))}
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper mt={[0, null, -64]}>
        <Container>
          <HowCanWeHelp
            advantages={adviser.advantages}
            title={"Jak vám pomůžu?"}
            bottomContent={{
              title: "Neváhejte mě oslovit",
              text: "Napište mi, s čím potřebujete poradit. Rád&nbsp;si&nbsp;s&nbsp;Vámi domluvím osobní schůzku.",
              cta: { text: "Ozvěte se mi", url: `#contact-form` },
            }}
          />
        </Container>
      </Wrapper>

      {adviser.divisions.includes("KF") && (
        <Wrapper>
          <Container justifyContent={"center"}>
            <Flex flexDirection={"column"} width={1} maxWidth={850} mb={4}>
              <Flex flexDirection={"column"} width={1}>
                <Heading as={"h2"} fontSize={[4, null, 5]} lineHeight={["24px", null, "38px"]} fontWeight={300} uppercase mb={20}>
                  Finanční poradenství
                </Heading>
                <Text fontSize={16} lineHeight={2} mb={3}>
                  Pro budování kariéry ve světě finančního poradenství vám nabízíme stabilitu, podpořenou bohatými zkušenostmi na českém trhu a zázemím
                  nadnárodní finanční skupiny Vienna Insurance Group (VIG). Máme pro vás špičkové digitální nástroje 21. století a podporu celé centrály, kde
                  jsou odborníci a experti na jednotlivé produkty a klientské služby.
                </Text>
              </Flex>
            </Flex>
          </Container>
        </Wrapper>
      )}

      {posts && (
        <Wrapper>
          <Container justifyContent={"center"}>
            <Box maxWidth={850}>
              <MagazineBlockBig posts={posts} pageType={"iwp"} hasNextPage={false} pageNumber={0} />
            </Box>
          </Container>

          <Container justifyContent={"center"} mb={4}>
            <Button variant={"secondary"} as={Link} href={URLS.blog}>
              Další články
            </Button>
          </Container>
        </Wrapper>
      )}

      {adviser.divisions.includes("GOLD") && <IwpGold adviser={adviser.personalNumber} posts={goldPosts} externalLink={URLS.goldBlog} />}

      <IwpContactBlock contactInfoWithName={contactInfoWithName} adviserPhoto={adviserPhoto} adviserId={adviser.id} />

      <CareerBlock
        background={"dark"}
        content={bannerCareer}
        image={<StaticImage style={{ borderRadius: "6px" }} src={"../../assets/images/kariera-blok.jpeg"} alt={"Kariéra"} />}
      />
    </Layout>
  );
};

export default IwpTemplate;

export const pageQuery = graphql`
  query PAGE_IWP_QUERY {
    wpGold {
      posts(first: 3) {
        nodes {
          id
          title
          uri
          slug
          date
          content: excerpt
          categories {
            nodes {
              id
              name
              link
              slug
            }
          }
          featuredImage {
            sourceUrl
          }
        }
      }
    }
    wp {
      posts(first: 2) {
        nodes {
          id
          title
          uri
          slug
          date
          content: excerpt
          categories {
            nodes {
              id
              name
              link
              slug
              uri
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
