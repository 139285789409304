import React, { Fragment } from "react";
import { Text } from "../../../style/Text";
import MagazineBlockBig from "../../../components/MagazineBlock/MagazineBlockBig";
import { URLS } from "../../../const/const";
import { Heading } from "../../../style/Heading";
import { Box, Container, Flex, Wrapper } from "../../../style/Grid";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "../../../style/Button";
import Icon from "../../../style/Icon";
import Link from "../../../components/Link";
import GoldProducts from "../../../components/GoldProducts/GoldProducts";

const IwpGold = ({ posts, adviser }) => (
  <Box border={0} width={1} borderTop={"4px"} borderColor={"gold.border"} borderStyle={"solid"}>
    <Wrapper>
      <Container justifyContent={"center"}>
        <Flex flexDirection={"column"} width={1} maxWidth={894} mb={4}>
          <Heading as={"h2"} fontSize={[4, null, 5]} lineHeight={["24px", null, "38px"]} fontWeight={300} uppercase mt={50} mb={20} color={"gold.headlines"}>
            Cenné kovy
          </Heading>
          <Text fontSize={16} lineHeight={2} mb={50}>
            Pro budování kariéry ve světě finančního poradenství vám nabízíme stabilitu, podpořenou bohatými zkušenostmi na českém trhu a zázemím nadnárodní
            finanční skupiny Vienna Insurance Group (VIG). Máme pro vás špičkové digitální nástroje 21. století a podporu celé centrály, kde jsou odborníci a
            experti na jednotlivé produkty a klientské služby.
          </Text>

          <Fragment>
            <Heading as={"h3"} fontSize={[4, null, 4]} lineHeight={["24px", null, "38px"]} fontWeight={300} mb={20} color={"gold.cta"}>
              Z e-shopu
            </Heading>
            <GoldProducts adviser={adviser} />
            <Box textAlign={"center"} mb={3}>
              <Button display={"inline-block"} variant="secondary" as={Link} fontSize={12} href={URLS.goldEshop + `?${adviser}`}>
                Další produkty
              </Button>
            </Box>
          </Fragment>
        </Flex>
      </Container>
    </Wrapper>

    <Wrapper>
      <Container justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
        <Flex maxWidth={894} bg={"panelSecondary"} borderRadius={6} mb={82}>
          <Box display={["none", null, "block"]} position={"relative"} minWidth={"320px"}>
            <StaticImage
              src={"../../../assets/images/iwp/spender.png"}
              alt=""
              style={{
                position: "relative",
                top: "32px",
                left: "-81px",
                right: "0",
                borderRadius: "6px",
                filter: "drop-shadow(3px 5px 15px rgba(101, 124, 136, 0.5));",
              }}
            />
          </Box>

          <Box py={[3, null, 4]} pr={[3, null, 4]} pl={[3, null, 0]} position={["static", null, "relative"]} right={"30px"}>
            <Heading as={"h3"} fontSize={[4, null, 5]} lineHeight={["24px", null, "38px"]} fontWeight={300} mb={20} color={"gold.cta"}>
              Odrazují vás jednorázové výdaje?
            </Heading>

            <Text fontSize={16} lineHeight={2} mb={3}>
              Ale přesto vás láká investování do vzácných kovů? Formou pravidelného spoření můžete investovat už od 500 Kč měsíčně
            </Text>

            <Box>
              <Button
                as={"a"}
                href={"https://www.kapitolgold.cz/vyvoj-cen/" + `?${adviser}`}
                variant="goldOutline"
                mr={3}
                display={"inline-block"}
                pl={25}
                pr={45}
                py={3}
                mb={3}
              >
                <span>Kalkulačka výnosů</span>
                <Icon icon="gold-calc" color="gold.border" size={20} />
              </Button>

              <Button as={"a"} href={"https://www.kapitolgold.cz/gold-form/" + `?${adviser}`} variant="gold" display={"inline-block"} px={25} py={3} mb={3}>
                Mám zájem investovat
              </Button>
            </Box>
          </Box>
        </Flex>

        <Box maxWidth={894} pb={50}>
          {posts && (
            <MagazineBlockBig
              title={"Novinky ze světa cenných kovů"}
              pageType={"iwp"}
              posts={posts}
              hasNextPage={false}
              pageNumber={0}
              pathPrefix={URLS.goldBlog}
              externalLink={URLS.goldBlog}
            />
          )}
        </Box>
      </Container>
    </Wrapper>
  </Box>
);

export default IwpGold;
