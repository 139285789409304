import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

const Blockquote = styled.blockquote`
  position: relative;
  margin: 0;
  padding: 35px 15px;
  font-size: 20px;
  line-height: 23px;
  color: ${themeGet("colors.headlines")};

  border-left: 4px solid ${themeGet("colors.cta")};
  border-width: 0 0 0 4px;
  border-style: solid;
  border-image: linear-gradient(90deg, #00b8a6 0%, #a4d9ce 100%) 1 100%;

  p {
    margin: 0;
  }

  &:before {
    content: "“";
    position: absolute;
    top: 24px;
    left: 10px;
    font-size: 100px;
    font-weight: 300;
    color: ${themeGet("colors.headlines")};
  }
  &:after {
    content: "„";
    position: absolute;
    bottom: 36px;
    right: 0;
    font-size: 100px;
    font-weight: 300;
    color: ${themeGet("colors.headlines")};
  }
`;

Blockquote.displayName = "Blockquote";

Blockquote.defaultProps = {};

Blockquote.propTypes = {};

export default Blockquote;
